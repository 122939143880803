import { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { UserRole } from '~modules/users/types/user-role-enum';
import { requiresAuth } from '~router/routes';

const Tournaments = lazy(() => import('./pages/Tournaments'));
const TournamentDetails = lazy(() => import('./pages/TournamentDetails'));
const EditTournament = lazy(() => import('./pages/EditTournament'));
const ReviewLeagueRanking = lazy(() => import('./pages/ReviewLeagueRanking'));
const ReviewTournamentBrackets = lazy(() =>
  import('./pages/ReviewTournamentBrackets')
);

export const tournamentsRoutes = {
  list: {
    title: () => 'Tournaments',
    path: () => '/tournaments',
    roles: [UserRole.ADMIN, UserRole.OPERATOR],
  },
  edit: {
    title: () => 'Edit Tournament',
    path: (id) => `/tournaments/${id ? id : ':id'}/edit`,
    roles: [UserRole.ADMIN, UserRole.OPERATOR],
  },
  details: {
    title: () => 'Tournament board',
    path: (id) => `/tournaments/${id ? id : ':id'}`,
    roles: [UserRole.ADMIN, UserRole.OPERATOR],
  },
  ranking: {
    title: () => 'League ranking',
    path: (leagueId) =>
      `/tournaments/league/${leagueId ? leagueId : ':leagueId'}/ranking`,
    roles: [UserRole.ADMIN, UserRole.OPERATOR],
  },
  preview: {
    title: () => 'Preview tournament',
    path: (leagueId, flight) =>
      `/tournaments/league/${leagueId ? leagueId : ':leagueId'}/preview/${
        flight ? flight : ':flight'
      }`,
    roles: [UserRole.ADMIN, UserRole.OPERATOR],
  },
};

export default function TournamentsRoutes() {
  return (
    <Switch>
      <Route
        exact
        component={requiresAuth(() => (
          <Tournaments title={tournamentsRoutes.list.title()} />
        ))}
        path={tournamentsRoutes.list.path()}
      />
      <Route
        exact
        component={requiresAuth(() => (
          <EditTournament title={tournamentsRoutes.edit.title()} />
        ))}
        path={tournamentsRoutes.edit.path()}
      />
      <Route
        exact
        component={requiresAuth(() => (
          <TournamentDetails title={tournamentsRoutes.details.title()} />
        ))}
        path={tournamentsRoutes.details.path()}
      />
      <Route
        exact
        component={requiresAuth(() => (
          <ReviewLeagueRanking
            title={tournamentsRoutes.ranking.title()}
            subMenuItems={[]}
          />
        ))}
        path={tournamentsRoutes.ranking.path()}
      />
      <Route
        exact
        component={requiresAuth(() => (
          <ReviewTournamentBrackets
            title={tournamentsRoutes.preview.title()}
            subMenuItems={[]}
          />
        ))}
        path={tournamentsRoutes.preview.path()}
      />
    </Switch>
  );
}
