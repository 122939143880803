import { accountRoutes } from '~modules/account/routes';
import { getCurrentUserRole } from '~modules/account/services/auth-service';
import { dartsRoutes } from '~modules/darts/routes';
import { UserRole } from '~modules/users/types/user-role-enum';

export function getInitialRoute() {
  const role = getCurrentUserRole();
  return role === UserRole.PLAYER
    ? accountRoutes.matchSignIn.path()
    : dartsRoutes.leagues.path();
}
