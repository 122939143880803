import { ApolloProvider } from '@apollo/client';
import React, { Suspense } from 'react';
import Snackbar from '~components/notifications/Snackbar';
import { useApollo } from '~config/apollo-client';
import Routes from '~router/routes';
import SplashScreen from '~layouts/SplashScreen';
import { GlobalStyles } from '~styles/index';
import '~styles/index.css';

function App() {
  return (
    <ApolloProvider client={useApollo()}>
      <GlobalStyles />
      <Suspense fallback={<SplashScreen />}>
        <Routes />
        <Snackbar />
      </Suspense>
    </ApolloProvider>
  );
}

export default App;
