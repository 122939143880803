import { gql } from '@apollo/client';

export default gql`
  mutation GenerateLeagueSchedule(
    $matches: [matches_insert_input!]!
    $league_id: uuid!
  ) {
    delete_games(
      where: { match: { division: { league_id: { _eq: $league_id } } } }
    ) {
      affected_rows
    }

    insert_matches(
      objects: $matches
      on_conflict: {
        constraint: matches_pkey
        update_columns: [
          week
          date
          flight
          home_league_team_id
          away_league_team_id
          location_id
          division_id
        ]
      }
    ) {
      affected_rows
    }
  }
`;
