import React, { ChangeEventHandler } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

const Checkmark = styled.span`
  border-radius: 4px;

  &:after {
    content: '';
    position: absolute;
    display: none;
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

const Label = styled.label`
  input:checked ~ ${Checkmark} {
    ${tw`bg-primary`};

    &:after {
      display: block;
    }
  }
`;

export interface CheckboxProps {
  label?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

const Checkbox: React.FC<CheckboxProps> = ({
  label,
  checked,
  disabled,
  onChange,
}) => {
  return (
    <Label className="group block h-5 select-none relative pl-8 font-barlow-semi-condensed cursor-pointer">
      {!!label && <span className="block text-gray-500">{label}</span>}
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className="absolute opacity-0 cursor-pointer h-0 w-0"
        disabled={disabled}
      />
      <Checkmark
        className={`${
          disabled ? 'bg-gray-100 opacity-50 cursor-not-allowed' : ''
        } bg-white absolute w-5 h-5 left-0 top-0 border border-primary group-hover:bg-gray-50`}
      />
    </Label>
  );
};

export default Checkbox;
