import React, { Context, useContext, useState } from 'react';
import styled from 'styled-components';

const ModalWrapper = styled.div`
  z-index: 9999;
`;

export type ModalContextType = {
  openModal: (options?: ModalOptions) => any;
  closeModal: () => any;
  options?: ModalOptions;
};

const ModalContext: Context<ModalContextType> = React.createContext({
  openModal: () => {},
  closeModal: () => {},
});

export interface ModalOptions {
  onClose?: () => any;
  isOpen?: boolean;
  content?: any;
}

const Modal: React.FC<ModalOptions> = (props) => {
  let { options } = useContext(ModalContext);

  function getOpenStatusClass() {
    return !options?.isOpen ? 'hidden' : 'visible';
  }

  return (
    <ModalWrapper
      className={`fixed inset-0 overflow-y-auto ${getOpenStatusClass()}`}
    >
      <div className="flex flex-col items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className={`fixed inset-0 transition-opacity`} aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        {/* This element is to trick the browser into centering the modal contents. */}
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        {/* This element is to trick the browser into centering the modal contents. */}
        {props.children}
      </div>
    </ModalWrapper>
  );
};

function useModal(): ModalContextType {
  const [options, setOptions] = useState<ModalOptions>({
    isOpen: false,
    content: null,
    onClose: () => {},
  });

  let openModal = (options?: ModalOptions) => {
    setOptions({
      ...options,
      isOpen: true,
    });
  };

  let closeModal = () => {
    setOptions({
      isOpen: false,
      content: null,
      onClose: () => {},
    });
  };

  return { openModal, closeModal, options };
}

export interface ModalProviderProps {}

const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  let { openModal, closeModal, options } = useModal();
  return (
    <ModalContext.Provider value={{ openModal, closeModal, options }}>
      {children}
      <Modal isOpen={options?.isOpen} onClose={options?.onClose}>
        {options?.content}
      </Modal>
    </ModalContext.Provider>
  );
};

export { ModalProvider, ModalContext };
