import React from 'react';
import ColorsGlobalStyle from './ColorsGlobalStyle';
import TypographyGlobalStyle from './TypographyGlobalStyle';

export const GlobalStyles = () => {
  return (
    <>
      <ColorsGlobalStyle />
      <TypographyGlobalStyle />
    </>
  );
};
