import { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { UserRole } from '~modules/users/types/user-role-enum';
import { requiresAuth } from '~router/routes';

const Locations = lazy(() => import('./pages/Locations'));
const AddLocation = lazy(() => import('./pages/AddLocation'));
const EditLocation = lazy(() => import('./pages/EditLocation'));

export const locationsRoutes = {
  home: {
    title: () => 'Locations',
    path: () => '/locations',
    roles: [UserRole.ADMIN],
  },
  add: {
    title: () => 'Add New Location',
    path: () => '/locations/add',
    roles: [UserRole.ADMIN],
  },
  edit: {
    title: () => 'Edit Location',
    path: (id) => `/locations/${id ? id : ':id'}`,
    roles: [UserRole.ADMIN],
  },
};

export default function LocationRoutes() {
  return (
    <Switch>
      <Route
        exact
        component={requiresAuth(
          () => (
            <Locations title={locationsRoutes.home.title()} />
          ),
          {
            roles: locationsRoutes.home.roles,
          }
        )}
        path={locationsRoutes.home.path()}
      />
      <Route
        exact
        component={requiresAuth(
          () => (
            <AddLocation title={locationsRoutes.add.title()} />
          ),
          {
            roles: locationsRoutes.add.roles,
          }
        )}
        path={locationsRoutes.add.path()}
      />
      <Route
        exact
        component={requiresAuth(
          () => (
            <EditLocation title={locationsRoutes.edit.title()} />
          ),
          {
            roles: locationsRoutes.edit.roles,
          }
        )}
        path={locationsRoutes.edit.path()}
      />
    </Switch>
  );
}
