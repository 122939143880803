import { mdiFileCancel } from '@mdi/js';
import Icon from '@mdi/react';
import Button from '~components/buttons/Button';
import colors from '~styles/colors';
import { signOut } from '../services/auth-service';

export default function Forbidden() {
  return (
    <>
      <div className={'h-screen flex flex-col justify-center'}>
        <div className="flex flex-col items-center justify-center">
          <div>
            {/* <img src={logoUrl} alt={'Logo'} /> */}
            <Icon path={mdiFileCancel} size={4} color={colors.primary} />
          </div>
          <div className={'mt-8 flex flex-col items-center'}>
            <h1>Forbidden</h1>
            <h3>You don't have permission to access this page</h3>
            <Button primary label={'Sign out'} onClick={() => signOut()} />
          </div>
        </div>
      </div>
    </>
  );
}
