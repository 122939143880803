import { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { requiresAuth } from '~router/routes';
import { UserRole } from './types/user-role-enum';

const Users = lazy(() => import('./pages/Users'));
const AddUser = lazy(() => import('./pages/AddUser'));
const EditUser = lazy(() => import('./pages/EditUser'));

export const usersRoutes = {
  list: {
    title: () => 'Administrators',
    path: () => '/admin-users',
    roles: [UserRole.ADMIN],
  },
  add: {
    title: () => 'Add Admin User',
    path: () => '/admin-users/add',
    roles: [UserRole.ADMIN],
  },
  edit: {
    title: () => 'Edit Admin User',
    path: (id) => `/admin-users/${id ? id : ':id'}`,
    roles: [UserRole.ADMIN],
  },
};

export default function UsersRoutes() {
  return (
    <Switch>
      <Route
        exact
        component={requiresAuth(
          () => (
            <Users title={usersRoutes.list.title()} />
          ),
          {
            roles: usersRoutes.list.roles,
          }
        )}
        path={usersRoutes.list.path()}
      />
      <Route
        exact
        component={requiresAuth(
          () => (
            <AddUser title={usersRoutes.add.title()} />
          ),
          {
            roles: usersRoutes.add.roles,
          }
        )}
        path={usersRoutes.add.path()}
      />
      <Route
        exact
        component={requiresAuth(
          () => (
            <EditUser title={usersRoutes.edit.title()} />
          ),
          {
            roles: usersRoutes.edit.roles,
          }
        )}
        path={usersRoutes.edit.path()}
      />
    </Switch>
  );
}
