import { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { UserRole } from '~modules/users/types/user-role-enum';
import { requiresAuth } from '~router/routes';

const Dashboard = lazy(() => import('./pages/Dashboard'));

export const dashboardRoutes = {
  home: {
    title: () => 'Dashboard',
    path: () => '/dashboard',
    roles: [UserRole.ADMIN, UserRole.OPERATOR],
  },
};

export default function LeaguesRoutes() {
  return (
    <Switch>
      <Route
        exact
        component={requiresAuth(
          () => (
            <Dashboard />
          ),
          {
            roles: dashboardRoutes.home.roles,
          }
        )}
        path={dashboardRoutes.home.path()}
      />
    </Switch>
  );
}
