import Icon from '@mdi/react';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import nsLogo from '../../../assets/images/ns-logo.png';
import Button from '../buttons/Button';

const Wrapper = styled.div<any>`
  @media (min-width: 640px) {
    width: ${(props: any) => (props.opened ? 245 : 83)}px;
    transition: all 0.1s linear;
  }
`;

export interface MainMenuProps {
  opened?: boolean;
  activeIndex?: number;
  items?: Array<MenuItemProps>;
}

export interface MenuItemProps {
  label: string;
  iconPath: string;
  opened?: boolean;
  active?: () => boolean;
  path?: string;
  onClick?: () => any;
}

const MenuItem: React.FC<MenuItemProps> = ({
  iconPath,
  label,
  opened,
  active,
  path,
  onClick,
}) => {
  const Btn = () => (
    <Button
      icon={<Icon size={1} path={iconPath} />}
      label={opened ? label : ''}
      primary={active && active()}
      stacked
      onClick={onClick}
    />
  );
  return path ? (
    <Link to={path} className="hover:no-underline">
      <Btn />
    </Link>
  ) : (
    <Btn />
  );
};

const MainMenu: React.FC<MainMenuProps> = ({ opened = false, items = [] }) => {
  const [isOpen, setIsOpen] = useState(opened);

  useEffect(() => {
    setIsOpen(opened);
  }, [opened]);

  return (
    <Wrapper
      className="bg-white border-r border-gray-200 p-4 w-full flex flex-row sm:w-auto sm:h-screen sm:flex-col items-center z-10"
      opened={isOpen}
      onMouseEnter={() => (items?.length > 0 ? setIsOpen(true) : void 0)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <div className="flex-grow sm:flex-row sm:items-start sm:justify-start sm:w-full sm:text-left sm:flex-grow-0">
        <img
          src={nsLogo}
          alt={'Network Sports logo'}
          className="w-12 mx-auto sm:self-start"
        />
      </div>
      <div className="hidden flex-grow w-full sm:flex flex-col mt-20 gap-3">
        {items?.map((item: MenuItemProps, index: number) => (
          <MenuItem
            key={index}
            label={item.label}
            iconPath={item.iconPath}
            active={item.active}
            path={item.path}
            opened={isOpen}
            onClick={() => {
              item.onClick && item.onClick();
            }}
          />
        ))}
      </div>
    </Wrapper>
  );
};

export default MainMenu;
