import { gql } from '@apollo/client';

export default gql`
  query allLeagues {
    leagues(where: { deleted: { _eq: false } }) {
      id
      name
      duration
      format
      region
      finals
      start_date
      sport
      active_weeks
      created_at
      updated_at
      status
      player_price
    }
  }
`;
