import { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { UserRole } from '~modules/users/types/user-role-enum';
import { requiresAuth } from '~router/routes';

const Operators = lazy(() => import('./pages/Operators'));
const AddOperator = lazy(() => import('./pages/AddOperator'));
const EditOperator = lazy(() => import('./pages/EditOperator'));

export const operatorsRoutes = {
  home: {
    title: () => 'Operators',
    path: () => '/operators',
    roles: [UserRole.ADMIN],
  },
  add: {
    title: () => 'Add New Operator',
    path: () => '/operators/add',
    roles: [UserRole.ADMIN],
  },
  edit: {
    title: () => 'Edit Operator',
    path: (id) => `/operators/${id ? id : ':id'}`,
    roles: [UserRole.ADMIN],
  },
};

export default function OperatorsRoutes() {
  return (
    <Switch>
      <Route
        exact
        component={requiresAuth(
          () => (
            <Operators title={operatorsRoutes.home.title()} />
          ),
          {
            roles: operatorsRoutes.home.roles,
          }
        )}
        path={operatorsRoutes.home.path()}
      />
      <Route
        exact
        component={requiresAuth(
          () => (
            <AddOperator title={operatorsRoutes.add.title()} />
          ),
          {
            roles: operatorsRoutes.add.roles,
          }
        )}
        path={operatorsRoutes.add.path()}
      />
      <Route
        exact
        component={requiresAuth(
          () => (
            <EditOperator title={operatorsRoutes.edit.title()} />
          ),
          {
            roles: operatorsRoutes.edit.roles,
          }
        )}
        path={operatorsRoutes.edit.path()}
      />
    </Switch>
  );
}
