import { Match } from '../types/Match';

export function playerIsAssignedToMatch(match?: Match, playerId?: string) {
  if (!match || !playerId) return false;

  const players = [
    ...(match?.home_league_team?.players || []),
    ...(match?.away_league_team?.players || []),
  ];

  return (
    players.some((p) => p.id === playerId) ||
    match?.substitutions?.some((s) => s.substitute_id === playerId)
  );
}
