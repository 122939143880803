import React, { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { UserRole } from '~modules/users/types/user-role-enum';
import { requiresAuth } from '~router/routes';

const Campaigns = lazy(() => import('./pages/Campaigns'));
const AddCampaign = lazy(() => import('./pages/AddCampaign'));
const EditCampaign = lazy(() => import('./pages/EditCampaign'));

export const campaignsRoutes = {
  home: {
    title: () => 'Campaigns',
    path: () => '/campaigns',
    roles: [UserRole.ADMIN, UserRole.OPERATOR],
  },
  add: {
    title: () => 'Add New Campaign',
    path: () => '/campaigns/add',
    roles: [UserRole.ADMIN, UserRole.OPERATOR],
  },
  edit: {
    title: () => 'Edit Campaign',
    path: (id) => `/campaigns/${id ? id : ':id'}`,
    roles: [UserRole.ADMIN, UserRole.OPERATOR],
  },
};

export default function CampaignRoutes() {
  return (
    <Switch>
      <Route
        exact
        component={requiresAuth(
          () => (
            <Campaigns title={campaignsRoutes.home.title()} />
          ),
          {
            roles: campaignsRoutes.home.roles,
          }
        )}
        path={campaignsRoutes.home.path()}
      />
      <Route
        exact
        component={requiresAuth(
          () => (
            <AddCampaign title={campaignsRoutes.add.title()} />
          ),
          {
            roles: campaignsRoutes.add.roles,
          }
        )}
        path={campaignsRoutes.add.path()}
      />
      <Route
        exact
        component={requiresAuth(
          () => (
            <EditCampaign title={campaignsRoutes.edit.title()} />
          ),
          {
            roles: campaignsRoutes.edit.roles,
          }
        )}
        path={campaignsRoutes.edit.path()}
      />
    </Switch>
  );
}
