import React from 'react';
import MainMenu from '~components/navigation/MainMenu';

export interface LoginLayoutProps {}

const LoginLayout: React.FC<LoginLayoutProps> = ({ children }) => {
  return (
    <div>
      <div className="fixed left-0 right-0 top-0 sm:left-0 sm:top-0 sm:right-auto">
        <MainMenu />
      </div>
      <div className="px-6 ml-0 pt-20 sm:pt-0 sm:px-0 sm:ml-20 box-border min-h-screen flex flex-col">
        {children}
      </div>
    </div>
  );
};

export default LoginLayout;
