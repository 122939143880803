import { gql } from '@apollo/client';

export default gql`
  query GetLeagueById($league_id: uuid!) {
    leagues_by_pk(id: $league_id) {
      id
      name
      duration
      format
      region
      finals
      start_date
      sport
      active_weeks
      created_at
      updated_at
      status
      player_price
    }
  }
`;
