import { uniqBy } from 'lodash';
import { Player } from '~modules/players/types/Player';
import { Match } from '~modules/schedule/types/Match';

export function getMatchPlayers(match?: Match): Player[] {
  const homeTeamPlayers = match?.home_league_team?.players || [];
  const awayTeamPlayers = match?.away_league_team?.players || [];

  return uniqBy([...homeTeamPlayers, ...awayTeamPlayers], 'id');
}
