import React from 'react';
import styled from 'styled-components';

export interface ButtonProps {
  disabled?: boolean;
  className?: string;
  alt?: string;
  label?: string;
  type?: 'button' | 'submit' | 'reset';
  icon?: JSX.Element | null;
  iconPosition?: 'left' | 'right';
  primary?: boolean;
  stacked?: boolean;
  size?: 'small' | 'inline' | 'default';
  onClick?: () => any;
}

export const StyledButton = styled.button<ButtonProps>`
  min-width: ${(props) => {
    if (props.icon || props.size === 'small' || props.size === 'inline')
      return 0;
    return 120;
  }}px;
`;

const Button: React.FC<ButtonProps> = ({
  label,
  className,
  alt,
  type = 'button',
  icon,
  iconPosition = 'left',
  stacked = false,
  primary = false,
  size = 'default',
  onClick,
  disabled,
}) => {
  function getCssClasses() {
    let classes = [
      'font-medium border rounded-md focus:outline-none flex flex-row items-center disabled:opacity-50',
    ];

    if (className) classes.push(className);

    if (primary) {
      classes.push(
        'bg-primary border-primary hover:bg-primary-darker hover:border-primary-darker text-color-on-primary'
      );
    } else {
      classes.push('bg-white text-gray-500 hover:bg-gray-100');
    }

    if (size === 'small') {
      if (icon) {
        classes.push('px-1.5 py-1.5');
      } else {
        classes.push('px-6 py-1.5');
      }
    } else {
      if (icon) {
        classes.push('px-3 py-3');
      } else {
        classes.push('px-6 py-3');
      }
    }

    if (stacked) {
      classes.push('w-full');
    }

    if (!icon && !!label) {
      classes.push('justify-center');
    }

    return classes.join(' ');
  }

  return (
    <StyledButton
      type={type}
      className={getCssClasses()}
      size={size}
      label={label}
      icon={icon}
      onClick={onClick}
      disabled={disabled}
      alt={alt}
    >
      {!!icon && iconPosition !== 'right' && (
        <div className={`${!!label ? 'mr-4' : ''}`}>{icon}</div>
      )}
      <span className="truncate overflow-clip">{label}</span>
      {!!icon && iconPosition === 'right' && (
        <div className={`${!!label ? 'ml-4' : ''}`}>{icon}</div>
      )}
    </StyledButton>
  );
};

export default Button;
