import { useQuery } from '@apollo/client';
import { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { GET_ALL_LEAGUES } from '~modules/leagues/graphql/queries';
import {
  getSportShortLabel,
  SportType,
} from '~modules/leagues/types/sport-type-enum';
import { UserRole } from '~modules/users/types/user-role-enum';
import { requiresAuth } from '~router/routes';

const Leagues = lazy(() => import('../leagues/pages/Leagues'));

const LeagueDetail = lazy(() => import('../leagues/pages/LeagueDetail'));
const EditMatch = lazy(() => import('../schedule/pages/EditMatch'));
const AddLeague = lazy(() => import('../leagues/pages/AddLeague'));
const MatchScoresheet = lazy(() => import('../leagues/pages/MatchScoresheet'));

export const dartsRoutes = {
  leagues: {
    title: () => 'Medalist Darts Leagues',
    path: () => '/darts/leagues',
    roles: [UserRole.ADMIN, UserRole.OPERATOR],
  },

  addLeague: {
    title: () => 'Add New League',
    path: () => '/darts/leagues/add',
    roles: [UserRole.ADMIN, UserRole.OPERATOR],
  },
  league: {
    title: () => 'League details',
    path: (id) => `/darts/leagues/${id ? id : ':id'}`,
    roles: [UserRole.ADMIN, UserRole.OPERATOR],
  },
  leagueTeams: {
    title: () => 'League Teams',
    path: (id, leagueTeamId) =>
      `/darts/leagues/${id ? id : ':id'}/teams/${
        leagueTeamId ? leagueTeamId : ':leagueTeamId'
      }`,
    roles: [UserRole.ADMIN, UserRole.OPERATOR],
  },
  leagueTeamDetail: {
    title: () => 'Team detail',
    path: (id, leagueTeamId) =>
      `/darts/leagues/${id ? id : ':id'}/teams/${
        leagueTeamId ? leagueTeamId : ':leagueTeamId'
      }`,
    roles: [UserRole.ADMIN, UserRole.OPERATOR],
  },
  assignTeams: {
    title: () => 'Assign Teams',
    path: (id) => `/darts/leagues/${id ? id : ':id'}/assign-teams`,
    roles: [UserRole.ADMIN, UserRole.OPERATOR],
  },
  leagueDivisions: {
    title: () => 'Add Divisions',
    path: (id) => `/darts/leagues/${id ? id : ':id'}/divisions`,
    roles: [UserRole.ADMIN, UserRole.OPERATOR],
  },
  reviewSchedule: {
    title: () => 'Review Schedule',
    path: (id) => `/darts/leagues/${id ? id : ':id'}/schedule`,
    roles: [UserRole.ADMIN, UserRole.OPERATOR],
  },
  editMatch: {
    title: () => 'Edit Match',
    path: (id, matchId) =>
      `/darts/leagues/${id ? id : ':id'}/schedule/${
        matchId ? matchId : ':matchId'
      }`,
    roles: [UserRole.ADMIN, UserRole.OPERATOR],
  },
  games: {
    title: () => 'Games',
    path: () => '/darts/games',
    roles: [UserRole.ADMIN, UserRole.OPERATOR],
  },

  leagueSchedule: {
    title: () => `Schedule`,
    path: (leagueId) =>
      `/darts/leagues/${leagueId ? leagueId : ':id'}/schedule`,
    roles: [UserRole.ADMIN, UserRole.OPERATOR],
  },

  // Remove
  leaguePlayers: {
    title: () => `Players`,
    path: (leagueId) => `/darts/leagues/${leagueId ? leagueId : ':id'}/players`,
    roles: [UserRole.ADMIN, UserRole.OPERATOR],
  },
  addLeaguePlayer: {
    title: () => `Add New Player`,
    path: (leagueId) =>
      `/darts/leagues/${leagueId ? leagueId : ':id'}/players/add`,
    roles: [UserRole.ADMIN, UserRole.OPERATOR],
  },
  editLeaguePlayer: {
    title: () => `Edit Player`,
    path: (leagueId, playerId) =>
      `/darts/leagues/${leagueId ? leagueId : ':id'}/players/${
        playerId ? playerId : ':playerId'
      }`,
    roles: [UserRole.ADMIN, UserRole.OPERATOR],
  },
  leaguePlayerStatistics: {
    title: () => `Player Statistics`,
    path: (leagueId, playerId) =>
      `/darts/leagues/${leagueId ? leagueId : ':id'}/players/${
        playerId ? playerId : ':playerId'
      }/statistics`,
    roles: [UserRole.ADMIN, UserRole.OPERATOR],
  },
  leagueReports: {
    title: () => `Reports`,
    path: (id) => `/darts/leagues/${id ? id : ':id'}/reports`,
    roles: [UserRole.ADMIN, UserRole.OPERATOR],
  },
  // Remove

  matchScoresheet: {
    title: () => `Match Scoresheet`,
    path: (id, matchCode) =>
      `/darts/leagues/${id ? id : ':id'}/matches/${
        matchCode ? matchCode : ':matchCode'
      }`,
    roles: [UserRole.ADMIN, UserRole.OPERATOR],
  },
};

export default function DartsRoutes() {
  const { data } = useQuery(GET_ALL_LEAGUES);
  const leagues = data?.leagues || [];

  return (
    <Switch>
      {/* Leagues */}
      <Route
        exact
        component={requiresAuth(
          () => (
            <Leagues
              title={dartsRoutes.leagues.title()}
              subMenuTitle={getSportShortLabel(SportType.DART)}
              subMenuItems={[]}
            />
          ),
          {
            roles: dartsRoutes.leagues.roles,
          }
        )}
        path={dartsRoutes.leagues.path()}
      />

      {/* Create League */}
      <Route
        exact
        component={requiresAuth(
          () => (
            <AddLeague
              title={dartsRoutes.addLeague.title()}
              subMenuTitle={getSportShortLabel(SportType.DART)}
              subMenuItems={[]}
            />
          ),
          {
            roles: dartsRoutes.addLeague.roles,
          }
        )}
        path={dartsRoutes.addLeague.path()}
      />

      {/* Edit Match */}
      <Route
        exact
        component={requiresAuth(
          () => (
            <EditMatch
              title={dartsRoutes.editMatch.title()}
              subMenuTitle={getSportShortLabel(SportType.DART)}
              subMenuItems={[]}
            />
          ),
          {
            roles: dartsRoutes.editMatch.roles,
          }
        )}
        path={dartsRoutes.editMatch.path()}
      />

      <Route
        exact
        component={requiresAuth(
          () => (
            <MatchScoresheet
              title={dartsRoutes.matchScoresheet.title('')}
              subMenuTitle={getSportShortLabel(SportType.DART)}
              sport={SportType.DART}
              leagues={leagues}
            />
          ),
          {
            roles: dartsRoutes.matchScoresheet.roles,
          }
        )}
        path={dartsRoutes.matchScoresheet.path()}
      />

      <Route
        component={requiresAuth(
          () => (
            <LeagueDetail
              subMenuTitle={getSportShortLabel(SportType.DART)}
              subMenuItems={[]}
            />
          ),
          {
            roles: dartsRoutes.league.roles,
          }
        )}
        path={dartsRoutes.league.path()}
      />
    </Switch>
  );
}
