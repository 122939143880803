import { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { UserRole } from '~modules/users/types/user-role-enum';
import { requiresAuth } from '~router/routes';

const Teams = lazy(() => import('./pages/Teams'));
const EditTeam = lazy(() => import('./pages/EditTeam'));

export const teamsRoutes = {
  list: {
    title: () => 'Teams',
    path: () => '/teams',
    roles: [UserRole.ADMIN],
  },
  edit: {
    title: () => 'Edit Team',
    path: (id) => `/teams/${id ? id : ':id'}`,
    roles: [UserRole.ADMIN],
  },
};

export default function TeamsRoutes() {
  return (
    <Switch>
      <Route
        exact
        component={requiresAuth(
          () => (
            <Teams title={teamsRoutes.list.title()} />
          ),
          {
            roles: teamsRoutes.list.roles,
          }
        )}
        path={teamsRoutes.list.path()}
      />
      <Route
        exact
        component={requiresAuth(
          () => (
            <EditTeam title={teamsRoutes.edit.title()} />
          ),
          {
            roles: teamsRoutes.edit.roles,
          }
        )}
        path={teamsRoutes.edit.path()}
      />
    </Switch>
  );
}
