import { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { UserRole } from '~modules/users/types/user-role-enum';
import { requiresAuth } from '~router/routes';

const RegisterHardware = lazy(() => import('./pages/RegisterHardware'));
const Hardwares = lazy(() => import('./pages/Hardware'));
const HardwareCredentials = lazy(() => import('./pages/HardwareCredentials'));
const AddHardwareCredential = lazy(() =>
  import('./pages/AddHardwareCredential')
);
const EditHardwareCredential = lazy(() =>
  import('./pages/EditHardwareCredential')
);
const AddHardware = lazy(() => import('./pages/AddHardware'));
const EditHardware = lazy(() => import('./pages/EditHardware'));
const HardwareLedger = lazy(() => import('./pages/HardwareLedger'));

export const hardwareRoutes = {
  home: {
    title: () => 'Hardware',
    path: () => '/hardware',
    roles: [UserRole.ADMIN],
  },
  add: {
    title: () => 'Add New Hardware',
    path: () => '/hardware/add',
    roles: [UserRole.ADMIN],
  },
  edit: {
    title: () => 'Edit Hardware',
    path: (id) => `/hardware/${id ? id : ':id'}`,
    roles: [UserRole.ADMIN],
  },
  credentials: {
    title: () => 'Hardware credentials',
    path: () => `/hardware/credentials`,
    roles: [UserRole.ADMIN],
  },
  addCredentials: {
    title: () => 'Add new Hardware credential',
    path: () => `/hardware/credentials/add`,
    roles: [UserRole.ADMIN],
  },
  editCredentials: {
    title: () => 'Edit Hardware credential',
    path: (code) => `/hardware/credentials/${code ? code : ':code'}`,
    roles: [UserRole.ADMIN],
  },
  ledger: {
    title: () => 'Hardware Ledger',
    path: (id) => `/hardware/${id ? id : ':hardwareId'}/ledger`,
    roles: [UserRole.ADMIN],
  },
  cabinetRegistration: {
    title: () => 'Register Cabinet',
    path: () => '/cabinet/register',
    roles: [UserRole.ADMIN],
  },
};

export default function HardwareRoutes() {
  return (
    <Switch>
      <Route
        exact
        component={requiresAuth(
          () => (
            <RegisterHardware />
          ),
          {
            roles: hardwareRoutes.cabinetRegistration.roles,
          }
        )}
        path={hardwareRoutes.cabinetRegistration.path()}
      />
      <Route
        exact
        component={requiresAuth(
          () => (
            <HardwareCredentials title={hardwareRoutes.credentials.title()} />
          ),
          {
            roles: hardwareRoutes.credentials.roles,
          }
        )}
        path={hardwareRoutes.credentials.path()}
      />
      <Route
        exact
        component={requiresAuth(
          () => (
            <AddHardwareCredential
              title={hardwareRoutes.addCredentials.title()}
            />
          ),
          {
            roles: hardwareRoutes.addCredentials.roles,
          }
        )}
        path={hardwareRoutes.addCredentials.path()}
      />
      <Route
        exact
        component={requiresAuth(
          () => (
            <EditHardwareCredential
              title={hardwareRoutes.editCredentials.title()}
            />
          ),
          {
            roles: hardwareRoutes.editCredentials.roles,
          }
        )}
        path={hardwareRoutes.editCredentials.path()}
      />
      <Route
        exact
        component={requiresAuth(
          () => (
            <Hardwares title={hardwareRoutes.home.title()} />
          ),
          {
            roles: hardwareRoutes.home.roles,
          }
        )}
        path={hardwareRoutes.home.path()}
      />
      <Route
        exact
        component={requiresAuth(
          () => (
            <AddHardware title={hardwareRoutes.add.title()} />
          ),
          {
            roles: hardwareRoutes.add.roles,
          }
        )}
        path={hardwareRoutes.add.path()}
      />
      <Route
        exact
        component={requiresAuth(
          () => (
            <EditHardware title={hardwareRoutes.edit.title()} />
          ),
          {
            roles: hardwareRoutes.edit.roles,
          }
        )}
        path={hardwareRoutes.edit.path()}
      />
      <Route
        exact
        component={requiresAuth(
          () => (
            <HardwareLedger />
          ),
          {
            roles: hardwareRoutes.ledger.roles,
          }
        )}
        path={hardwareRoutes.ledger.path()}
      />
    </Switch>
  );
}
