import { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { UserRole } from '~modules/users/types/user-role-enum';
import { requiresAuth } from '~router/routes';

const Players = lazy(() => import('./pages/Players'));
const EditPlayer = lazy(() => import('./pages/EditPlayer'));
const AddPlayer = lazy(() => import('./pages/AddPlayer'));

export const playersRoutes = {
  list: {
    title: () => 'Players',
    path: () => '/players',
    roles: [UserRole.ADMIN],
  },
  add: {
    title: () => 'Add Player',
    path: () => '/players/add',
    roles: [UserRole.ADMIN],
  },
  edit: {
    title: () => 'Edit Player',
    path: (id) => `/players/${id ? id : ':id'}`,
    roles: [UserRole.ADMIN],
  },
};

export default function PlayersRoutes() {
  return (
    <Switch>
      <Route
        exact
        component={requiresAuth(
          () => (
            <Players title={playersRoutes.list.title()} />
          ),
          {
            roles: playersRoutes.list.roles,
          }
        )}
        path={playersRoutes.list.path()}
      />
      <Route
        exact
        component={requiresAuth(
          () => (
            <AddPlayer title={playersRoutes.add.title()} />
          ),
          {
            roles: playersRoutes.add.roles,
          }
        )}
        path={playersRoutes.add.path()}
      />
      <Route
        exact
        component={requiresAuth(
          () => (
            <EditPlayer title={playersRoutes.edit.title()} />
          ),
          {
            roles: playersRoutes.edit.roles,
          }
        )}
        path={playersRoutes.edit.path()}
      />
    </Switch>
  );
}
