import qs from 'qs';
import { lazy } from 'react';
import toast from 'react-hot-toast';
import { matchPath, Redirect, Route, Switch } from 'react-router-dom';
import SplashScreen from '~layouts/SplashScreen';
import { UserRole } from '~modules/users/types/user-role-enum';
import { getInitialRoute } from '~router/get-initial-route';
import { redirectIfLoggedIn, requiresAuth } from '~router/routes';
import Forbidden from './pages/Forbidden';
import PlayerSubstitution from './pages/PlayerSubstitution';
import { login, parseHashAndAuthenticate } from './services/auth-service';

const Login = lazy(() => import('./pages/Login'));
const MatchSignIn = lazy(() => import('./pages/MatchSignIn'));
const RecoverPassword = lazy(() => import('./pages/RecoverPassword'));
const MyAccount = lazy(() => import('./pages/MyAccount'));

export const accountRoutes = {
  login: {
    title: () => 'Sign In',
    path: () => '/login',
    roles: [],
  },
  playerAuthentication: {
    title: () => 'Authenticate',
    path: () => '/play',
    roles: [UserRole.PLAYER],
  },
  matchSignIn: {
    title: () => 'Sign in to league match',
    path: () => '/play/match',
    roles: [UserRole.PLAYER],
  },
  privateMatchSignIn: {
    title: () => 'Sign in to a private match',
    path: (token) => `/play/private-match/${token ? token : ':token'}`,
    roles: [UserRole.PLAYER],
  },
  playerSubstitution: {
    title: () => 'Substitute a player',
    path: (matchCode) =>
      `/play/match/${matchCode ? matchCode : ':matchCode'}/substitute`,
    roles: [UserRole.PLAYER],
  },
  authCallback: {
    title: () => 'Authenticating...',
    path: () => '/auth-callback',
    roles: [],
  },
  logoutCallback: {
    title: () => 'Logged out',
    path: () => '/logout',
    roles: [],
  },
  forbidden: {
    title: () => 'Forbidden',
    path: () => '/403',
    roles: [],
  },
  myAccount: {
    title: () => 'My Account',
    path: () => '/my-account',
    roles: [],
  },
  recoverPassword: {
    title: () => 'Recover password',
    path: () => '/recover-password',
    roles: [],
  },
};

function AccountRoutes() {
  const initialRoute = getInitialRoute();
  return (
    <Switch>
      <Route
        exact
        component={redirectIfLoggedIn(initialRoute, () => (
          <Login />
        ))}
        path={accountRoutes.login.path()}
      />

      <Route
        exact
        component={requiresAuth(
          () => (
            <MatchSignIn />
          ),
          {
            redirect: () => {
              login(accountRoutes.matchSignIn.path());
            },
            roles: accountRoutes.matchSignIn.roles,
          }
        )}
        path={accountRoutes.matchSignIn.path()}
      />

      <Route
        exact
        component={requiresAuth(
          () => (
            <MatchSignIn />
          ),
          {
            redirect: () => {
              const { params } = matchPath(window.location.pathname, {
                path: accountRoutes.privateMatchSignIn.path(),
              });
              login(accountRoutes.privateMatchSignIn.path(params?.token));
            },
            roles: accountRoutes.privateMatchSignIn.roles,
          }
        )}
        path={accountRoutes.privateMatchSignIn.path()}
      />

      <Route
        exact
        component={requiresAuth(
          () => (
            <PlayerSubstitution />
          ),
          {
            redirect: accountRoutes.playerAuthentication.path(),
            roles: accountRoutes.playerSubstitution.roles,
          }
        )}
        path={accountRoutes.playerSubstitution.path()}
      />

      <Route
        exact
        render={() => {
          try {
            parseHashAndAuthenticate(window.location.hash, initialRoute);
          } catch (err) {
            toast(`Something went wrong, please try again later.`);
            return <Redirect to={accountRoutes.login.path()} />;
          }
        }}
        path={accountRoutes.authCallback.path()}
      />

      <Route
        exact
        render={() => {
          const queryString = qs.parse(window.location.search, {
            ignoreQueryPrefix: true,
          });
          login(queryString.returnUrl || accountRoutes.login.path());
          return <SplashScreen />;
        }}
        path={accountRoutes.logoutCallback.path()}
      />

      <Route
        exact
        render={() => {
          return <Forbidden />;
        }}
        path={accountRoutes.forbidden.path()}
      />

      <Route
        exact
        component={redirectIfLoggedIn(initialRoute, () => (
          <RecoverPassword />
        ))}
        path={accountRoutes.recoverPassword.path()}
      />

      <Route
        exact
        component={requiresAuth(
          () => (
            <MyAccount title={accountRoutes.myAccount.title()} />
          ),
          {
            roles: accountRoutes.myAccount.roles,
          }
        )}
        path={accountRoutes.myAccount.path()}
      />
    </Switch>
  );
}

export default AccountRoutes;
