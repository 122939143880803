import { ApolloClient, HttpLink, InMemoryCache, split } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
import { getAuthToken } from '~modules/account/services/auth-service';
import { typePolicies as hardwareTypePolices } from '~modules/hardware/graphql/configs';
import { GRAPHQL_API_ENDPOINT, HASURA_ADMIN_SECRET } from './global-variables';

let apolloClient: ApolloClient<any>;

// Apollo Client Web v3.5.10 has a GraphQLWsLink class which implements
// graphql-ws directly. For older versions, see the next code block
// to define your own GraphQLWsLink.

function getWebSocketBaseUrl() {
  return GRAPHQL_API_ENDPOINT?.replace('https://', 'wss://')?.replace(
    'http://',
    'ws://'
  );
}

const createApolloClient = () => {
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    }

    if (networkError)
      console.log(`[Network error]: ${networkError.toString()}`);
  });

  const wsLink = new GraphQLWsLink(
    createClient({
      url: getWebSocketBaseUrl(),
      connectionParams: {
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
          'x-hasura-admin-secret': HASURA_ADMIN_SECRET,
        },
      },
    })
  );

  const httpLink = new HttpLink({
    uri: GRAPHQL_API_ENDPOINT,
    headers: {
      'x-hasura-admin-secret': HASURA_ADMIN_SECRET,
    },
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink
  );

  return new ApolloClient({
    link: errorLink.concat(splitLink),
    cache: new InMemoryCache({
      typePolicies: {
        ...hardwareTypePolices,
      },
    }),
  });
};

export function useApollo() {
  if (!apolloClient) {
    apolloClient = createApolloClient();
    return apolloClient;
  } else {
    return apolloClient;
  }
}
