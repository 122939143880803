import { createBrowserHistory } from 'history';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Redirect, Route, Router, useLocation } from 'react-router-dom';
import { ModalProvider } from '~components/modals/Modal';
import SplashScreen from '~layouts/SplashScreen';
import AccountRoutes, { accountRoutes } from '~modules/account/routes';
import {
  getCurrentUserRole,
  isAnonymous,
} from '~modules/account/services/auth-service';
import CampaignsRoutes, { campaignsRoutes } from '~modules/campaigns/routes';
import DartsRoutes, { dartsRoutes } from '~modules/darts/routes';
import DashboardRoutes, { dashboardRoutes } from '~modules/dashboard/routes';
import HardwareRoutes, { hardwareRoutes } from '~modules/hardware/routes';
import LocationsRoutes, { locationsRoutes } from '~modules/locations/routes';
import OperatorsRoutes, { operatorsRoutes } from '~modules/operators/routes';
import PlayersRoutes, { playersRoutes } from '~modules/players/routes';
import TeamsRoutes, { teamsRoutes } from '~modules/teams/routes';
import TournamentsRoutes, {
  tournamentsRoutes,
} from '~modules/tournaments/routes';
import UsersRoutes, { usersRoutes } from '~modules/users/routes';
import { getInitialRoute } from './get-initial-route';
import { parseRoutesToArray } from './parse-routes-to-array';

export const history = createBrowserHistory();
export const allRoutes = parseRoutesToArray([
  dartsRoutes,
  accountRoutes,
  dashboardRoutes,
  hardwareRoutes,
  locationsRoutes,
  campaignsRoutes,
  operatorsRoutes,
  playersRoutes,
  teamsRoutes,
  usersRoutes,
  tournamentsRoutes,
]);

export function requiresAuth(
  renderFn,
  options = { redirect: accountRoutes.login.path(), roles: [] }
) {
  return () => {
    const isAuthenticated = !isAnonymous();
    if (!isAuthenticated && !!options.redirect) {
      if (typeof options.redirect === 'string') {
        return <Redirect to={options.redirect} />;
      } else if (typeof options.redirect === 'function') {
        options.redirect();
        return <SplashScreen />;
      }
    }

    const userRole = getCurrentUserRole();
    const hasPermissions =
      !options?.roles?.length || options.roles.includes(userRole);
    if (!hasPermissions) {
      return <Redirect to={getInitialRoute()} />;
    }

    return renderFn();
  };
}

export function redirectIfLoggedIn(to, renderFn) {
  return () => {
    if (!isAnonymous()) {
      return <Redirect to={to} />;
    }
    return renderFn();
  };
}

function ScrollToTopOnLocationChange() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function Routes(props) {
  const initialRoute = getInitialRoute();
  return (
    <Router history={history}>
      <ScrollToTopOnLocationChange />
      <ModalProvider>
        <DashboardRoutes />
        <DartsRoutes />
        <TournamentsRoutes />
        <OperatorsRoutes />
        <PlayersRoutes />
        <TeamsRoutes />
        <LocationsRoutes />
        <CampaignsRoutes />
        <HardwareRoutes />
        <AccountRoutes />
        <UsersRoutes />
        <Route
          exact
          path={'/'}
          render={requiresAuth(() => (
            <Redirect to={initialRoute} />
          ))}
        />
        {props.children}
      </ModalProvider>
    </Router>
  );
}

Routes.propTypes = { children: PropTypes.any };

export default Routes;
