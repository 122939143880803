import { gql } from '@apollo/client';

export default gql`
  query GetAllGames($from: timestamptz!) {
    games(
      where: {
        deleted: { _eq: false }
        attempts: { created_at: { _gte: $from } }
      }
    ) {
      id
      type
      teams {
        id
        players {
          id
          rating
          cricket_rating
          ohone_rating
          user {
            id
            first_name
            last_name
          }
        }
        score
        game_id
        league_team_id
      }
      attempts {
        id
        type
        event
        turn
        player_id
        player {
          id
          rating
          cricket_rating
          ohone_rating
          user {
            id
            first_name
            last_name
          }
        }
        score
        created_at
        updated_at
      }
      played_at
      winner_team_id
      match_id
      cabinet_id
      params
    }
  }
`;
