import auth0 from 'auth0-js';
import jwt_decode from 'jwt-decode';
import qs from 'qs';
import {
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  AUTH0_REALM,
} from '~config/global-variables';
import { UserRole } from '~modules/users/types/user-role-enum';

const TOKEN_KEY = 'ns.token';

export interface CurrentUserProfile {
  sub?: string;
  nickname?: string;
  name?: string;
  picture?: string;
  updated_at?: Date;
  email?: string;
  email_verified?: string;
  'https://hasura.io/jwt/claims'?: {
    'x-hasura-allowed-roles'?: string[];
    'x-hasura-default-role'?: string;
    'x-hasura-role'?: string;
    'x-hasura-user-id'?: string;
  };
}

export const webAuth = new auth0.WebAuth({
  domain: AUTH0_DOMAIN,
  clientID: AUTH0_CLIENT_ID,
});

function tokenIsExpired(token: string) {
  try {
    let decodedToken: any = jwt_decode(token);
    return decodedToken.exp * 1000 <= new Date().getTime();
  } catch (err) {
    return true;
  }
}

function getAuthReturnUrl() {
  let queryParams = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  return queryParams.returnUrl as string;
}

export function getAuthToken() {
  let token: string = localStorage?.getItem(TOKEN_KEY) || '';
  return !tokenIsExpired(token) ? token : '';
}

export function isAuthenticated() {
  return !!getAuthToken();
}

export function isAnonymous() {
  return !isAuthenticated();
}

export function getCurrentUserId() {
  try {
    let currentUser = getCurrentUserProfile();
    return currentUser?.sub?.replace('auth0|', '');
  } catch (err) {
    return null;
  }
}

export function getCurrentUserProfile(): CurrentUserProfile | null {
  try {
    return jwt_decode(getAuthToken()) as CurrentUserProfile;
  } catch (err) {
    return null;
  }
}

export function getCurrentUserRole(): UserRole | undefined {
  const profile = getCurrentUserProfile();
  if (!profile) return;
  const claims = profile['https://hasura.io/jwt/claims'] || {};
  return claims['x-hasura-role'] as UserRole;
}

export function login(returnUrl?: string) {
  webAuth.authorize({
    responseType: 'id_token',
    redirectUri: `${window.location.origin}/auth-callback${
      returnUrl ? `?returnUrl=${returnUrl}` : ``
    }`,
  });
}

export function recoverPassword(email: string): Promise<string> {
  return new Promise((resolve, reject) => {
    webAuth.changePassword(
      {
        connection: AUTH0_REALM,
        email: email,
      },
      (err, result) => {
        if (err) {
          return reject(err);
        }
        resolve(result);
      }
    );
  });
}

export function parseHashAndAuthenticate(hash: string, redirectTo: string) {
  webAuth.parseHash(
    { hash },
    (
      err: auth0.Auth0ParseHashError | null,
      authResult: auth0.Auth0DecodedHash | null
    ) => {
      if (err || !authResult?.idToken) {
        throw err;
      }
      setAuthToken(authResult?.idToken);
      const returnUrl = getAuthReturnUrl();
      window.location.href = returnUrl || redirectTo;
    }
  );
}

export async function signOut(returnUrl?: string) {
  const { accountRoutes } = await import('../routes');
  localStorage.removeItem(TOKEN_KEY);
  webAuth.logout({
    returnTo: `${
      window.location.origin
    }${accountRoutes.logoutCallback.path()}?returnUrl=${
      returnUrl || accountRoutes.login.path()
    }`,
  });
}

export function setAuthToken(token: string) {
  localStorage?.setItem(TOKEN_KEY, token);
}
